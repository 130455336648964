import Vue from 'vue'
import App from './App.vue'
import './scss/custom.scss'

Vue.config.productionTip = false


Vue.config.ignoredElements = [
  'a-scene',
  'a-asset-item',
  'a-assets',
  'a-box',
  'a-cylinder',
  'a-text',
  'a-sphere',
  'a-plane',
  'a-sky',
  'a-camera',
  'a-cursor',
  'a-entity'
]

Vue.prototype.$eventHub = new Vue(); // Global event bus

new Vue({
  render: h => h(App),
}).$mount('#app')


