<template>
    <transition name="slide">
        <div class="sub-nav" v-if="navItems">
            <div class="scroll-container">
                <a class="nav-element" v-bind:class="{ active: navItem === activeNav }" @click="navItemClicked(navItem,index)"
                   v-for="(navItem,index) in navItems"
                   :key="index">{{navItem.title}}</a>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'FooterSubNavigation',
        props: {
            navItems: Object
        },
        data() {
            return {
                activeNav: undefined,
                activeNavIndex : 0
            }
        },
        methods: {
            navItemClicked: function (navEntry,index) {

                if (navEntry.onClick) {
                    this.$eventHub.$emit('nav-item-clicked', navEntry.onClick);
                    this.activeNav = navEntry;
                    this.activeNavIndex = Object.keys(this.navItems).indexOf(index);
                }
            }
        },
        watch : {
            activeNavIndex : function() {
                const activeNavElement = document.getElementsByClassName("scroll-container")[0].getElementsByClassName("active")[0];


                // compute middle
                const scrollLeft = this.activeNavIndex * activeNavElement.offsetWidth;
                console.log("Will Scroll to : " + scrollLeft)

                // .scrollLeft = scrollLeft;

                /* gloval $ */
                $(document.getElementsByClassName("scroll-container")[0]).animate({
                    scrollLeft : scrollLeft
                })

            },

            navItems : function() {
                console.log("nav items changed - select first entry");
                this.activeNav = Object.values(this.navItems)[0];
                this.activeNavIndex = 0
                this.$eventHub.$emit('nav-item-clicked', this.activeNav.onClick);

            }
        },
        mounted() {

            const self = this;

            this.$eventHub.$on('nav-next-item', () => {
                console.log("subnav next");
                self.activeNavIndex = (self.activeNavIndex +1)  % Object.keys(self.navItems).length;
                self.activeNav = self.navItems[Object.keys(self.navItems)[self.activeNavIndex]];
                self.$eventHub.$emit('nav-item-clicked', self.activeNav.onClick);
            });

            this.$eventHub.$on('nav-prev-item', () => {
                console.log("subnav prev");
                this.activeNavIndex = this.activeNavIndex-1;
                if (this.activeNavIndex == -1) {
                    this.activeNavIndex = Object.keys(self.navItems).length -1;
                }
                self.activeNav = self.navItems[Object.keys(self.navItems)[self.activeNavIndex]];
                self.$eventHub.$emit('nav-item-clicked', self.activeNav.onClick);
            });

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .sub-nav {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $oralGrayLight;


        .scroll-container {
            overflow-x: scroll;
            min-width: 100%;
            display: flex;

            .nav-element {

                &.active {
                    font-weight: bold;
                    color: $ioBlue;
                }

                //background: rgba(0, 47, 108, 0.2);
                background: #fff;
                color: black;
                text-transform: uppercase;
                padding: .4rem 0.25rem;
                border-radius: 25rem;
                margin: .75rem 0.45rem;
                letter-spacing: 0.065rem;
                font-size: 0.7rem;
                text-align: center;
                position: relative;
                min-width: 42.5%;
                box-shadow: -0.5px 0.5px 2px rgb(0 0 0 / 0.08);

                &:first-of-type {
                    //margin-left: 0.45rem;
                }

                /*
            &:last-of-type:before {
                content: initial;
            }

             */

            }
        }
    }

    .enter {
        transform: translateX(100%)
    }

    .enter-to {
        transform: translateX(0)
    }

    .slide-enter-active {
        position: absolute
    }

    .leave {
        transform: translateX(0)
    }

    .leave-to {
        transform: translateX(-100%)
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: all 750ms ease-in-out
    }


</style>
