<template>
    <div class="info-popup" v-if="visible">
        <div class="icon" v-if="iConPath">
            <img :src="iConPath"/>
        </div>
        <div class="info">
            <h2 v-html="title"/>
            <p v-html="text"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InfoPopUp',
        data() {
            return {
                visible: false,
                iConPath: "",
                title: "",
                text: ""
            }
        },
        created() {
            this.$eventHub.$on('showInfoPopUp', this.showInfoPopUp);
            this.$eventHub.$on('hideInfoPopUp', this.hideInfoPopUp);
        },
        beforeDestroy() {
            this.$eventHub.$off('showInfoPopUp');
        },
        methods: {
            showInfoPopUp(data) {
                this.iConPath = data.iconPath;
                this.title = data.title;
                this.text = data.text;
                this.visible = true;
            },
            hideInfoPopUp() {
                this.visible = false;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .info-popup {
        background: $oralGrayLight;
        position: absolute;
        z-index: 1;
        top: 7%;
        display: flex;
        color: $oralGray;
        padding: 1.25rem 1rem 1rem .75rem;
        align-items: center;
        width: 100%;
        animation: slide-in .2s ease-in-out alternate;

        .icon {
            width: 20%;

            img {
                width: 100%;
            }
        }

        .info {
            width: 80%;
            margin-left: 1rem;

            h2 {
                font-size: 1.35rem;
                margin-bottom: .65rem;
            }

            p {
                font-size: .85rem;
            }
        }

    }

    @keyframes slide-in {
        from {
            transform: translateY(-100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }


</style>
