const nav = {
    "Highlights": {
        "title": "Highlights",
        "onClick": {
            "VrView": {
                "setScene": "Highlights"
            }
        },
        "subNav": {
            "Mikrovibrationen": {
                "title": "Mikrovibrationen",
                "onClick": {
                    "VrView": {
                        "setScene": "Mikrovibrationen"
                    }
                }
            },
            "Zahnfleischschutz": {
                "title": "Zahnfleischschutz",
                "onClick": {
                    "VrView": {
                        "setScene": "Zahnfleischschutz"
                    }
                }
            },
            "OLEDDisplay": {
                "title": "OLED Display",
                "onClick": {
                    "VrView": {
                        "setScene": "OledDisplay"
                    }
                }
            },
            "SmartModes": {
                "title": "7 Smart Modes",
                "onClick": {
                    "VrView": {
                        "setScene": "SmartModes"
                    }
                }
            },
            "AI": {
                "title": "A.I.",
                "onClick": {
                    "VrView": {
                        "setScene": "AI"
                    }
                }
            },
            "Magnetantrieb": {
                "title": "Magnetic Drive",
                "onClick": {
                    "VrView": {
                        "setScene": "MagneticDrive"
                    }
                }
            },
        }
    },
    "Extras": {
        "title": "Extras",
        "onClick": {
            "VrView": {
                "setScene": "Extras"
            }
        },
        "subNav": {
            "Charger": {
                "title": "Magnetic Charger",
                "onClick": {
                    "VrView": {
                        "setScene": "Charger"
                    }
                }
            },
            "RefillHead": {
                "title": "Refill Head",
                "onClick": {
                    "VrView": {
                        "setScene": "RefillHead"
                    }
                }
            },
            "Case": {
                "title": "Premium Case",
                "onClick": {
                    "VrView": {
                        "setScene": "Case"
                    }
                }
            }
        }
    },
    "Shop": {
        "title": "Shop",
        "onClick": {
            "VrView": {
                "setScene": "Shop"
            }
        }
    }
}

export {nav}
