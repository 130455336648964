<template>
    <div class="overlay">
        <Origami class="loading-spinner"/>
    </div>
</template>

<script>
    import {Origami} from 'vue-loading-spinner'

    export default {
        name: 'LoadingScreen',
        props: {},
        components: {
            Origami,
        },
        methods: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .overlay {
        z-index: 99999;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        opacity: 1;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $oralGrayLight;

        .loading-spinner {
            //z-index: 999999;
        }
    }

</style>
