const vrScenes = {

    "Highlights": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
            },
            onHide: function () {
            }
        },
    },
    "Mikrovibrationen": {
        "objectStates": {
            "onShow": {
                "brush-head-animation" : {
                    "visible" : "true"
                },
                "Oral_B_Kopf" : {
                    "visible" : "false"
                }
            },
            "onHide": {
                "brush-head-animation" : {
                    "visible" : "false"
                },
                "Oral_B_Kopf" : {
                    "visible" : "true"
                }
            }
        },
        callbacks: {
            onShow: function () {
                console.log('onShow Mikrovibes')

                var camera = document.getElementById('camera')
                camera.emit('microVibe')

                /*
                var brush = document.getElementById('brush')
                brush.el.setAttribute("animation-mixer", "clip", "start")
                 */

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Mikrovibrationen",
                    text: "Die iO-Magnettechnologie vereint die einzigartige Mikrovibration der Borsten mit dem runden Bürstenkopf von Oral-B für ein überraschend sanftes Zahnputzerlebnis."
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        },
    },
    "OledDisplay": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
                console.log('onShow Mikrovibes')
                var camera = document.getElementById('camera')
                camera.emit('showOLED')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Interaktives Display",
                    text: "Das interaktive Farbdisplay zeigt Ihnen den Ladestand an, gibt Ihnen Feedback zum Putzverhalten und ermöglicht die Auswahl zwischen 6 Putzmodi."
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    },
    "Zahnfleischschutz": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
                console.log('onShow Zahnfleisch')
                var camera = document.getElementById('camera')
                camera.emit('showZahnfleisch')

                var lightRing = document.getElementById('Oral_B_Light')

                //lightRing.emit('changeColor')

                setTimeout(function() {
                    lightRing.object3DMap.mesh.material.emissive.r = '255'
                    lightRing.object3DMap.mesh.material.emissive.g = '0'
                    lightRing.object3DMap.mesh.material.emissive.b = '0'
                }, 3000);

                setTimeout(function() {
                    lightRing.object3DMap.mesh.material.emissive.r = '0'
                    lightRing.object3DMap.mesh.material.emissive.g = '255'
                    lightRing.object3DMap.mesh.material.emissive.b = '0'
                }, 1000);


                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Zahnfleischschutz",
                    text: "Die intelligente Andruckkontrolle leuchtet rot bei zu viel Druck und grün bei optimalem Druck um das Zahnfleisch zu schützen."
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    },
    "SmartModes": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
                console.log('onShow SmartModes')
                var camera = document.getElementById('camera')
                camera.emit('showSmartModes')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "7 Smart Modi",
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                });

                const panels = ["Oral_B_Panel","Oral_B_Panel1","Oral_B_Panel2"];
                let i = 0;

                if (!this.displaySwitchTimer) {
                    this.displaySwitchTimer = setInterval(function(){
                        i = (i + 1) % panels.length;
                        document.getElementById("Oral_B_Panel").setAttribute("visible", false)
                        document.getElementById("Oral_B_Panel1").setAttribute("visible", false)
                        document.getElementById("Oral_B_Panel2").setAttribute("visible", false)
                        document.getElementById(panels[i]).setAttribute("visible", true)
                    }, 1000);

                }



            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    },
    "AI": {
        "objectStates": {
            "onShow": {
                "phone": {
                    "visible": "true"
                }
            },
            "onHide": {
                "phone": {
                    "visible": "false"
                }
            }
        },
        callbacks: {
            onShow: function () {
                console.log('onShow AI')
                var camera = document.getElementById('camera')
                camera.emit('showAI')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Künstliche Intelligenz",
                    text: "Die App unterstützt Sie beim täglichen Putzen."
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    },
    "MagneticDrive": {
        "objectStates": {
            "onShow": {
                "brush": {
                    "visible": "false"
                },
                "brushCut": {
                    "visible": "true"
                }
            },
            "onHide": {

                "brush": {
                    "visible": "true"
                },
                "brushCut": {
                    "visible": "false"
                }
            }
        },
        callbacks: {
            onShow: function () {
                console.log('onShow Magnetic Drive')
                var camera = document.getElementById('camera')
                camera.emit('showMagneticDrive')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Magnetantrieb",
                    text: "Das lineare Magnetantriebssystem leitet die Energie in die Borstenspitzen des runden Oral-B Bürstenkopfs und kreiert sanfte Mikrovibrationen, um eine bessere Plaque-Entfernung* und ein erstaunlich angenehmes Zahnputzerlebnis zu ermöglichen."
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    },
    "Charger": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
                console.log('onShow Charger')
                var camera = document.getElementById('camera')
                camera.emit('showCharger')
                var brush = document.getElementById('brush')
                brush.emit('showCharger')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Magnetische Ladestation",
                    text: "Die neue kompakte magnetische Ladestation lädt Ihre Zahnbürste in ungefähr 3 Stunden auf"
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
                var brush = document.getElementById('brush')
                brush.emit('showChargerEnd')
            }
        }
    },
    "RefillHead": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
                console.log('onShow RefillHead')
                var camera = document.getElementById('camera')
                camera.emit('showRefillHead')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Reise-Etui für Aufsteckbürsten",
                    text: "Sauber & elegant – das Etui kümmert sich um ihre Aufsteckbürsten auf jeder Reise. "
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    },
    "Case": {
        "objectStates": {
            "onShow": {},
            "onHide": {}
        },
        callbacks: {
            onShow: function () {
                console.log('onShow Case')
                var camera = document.getElementById('camera')
                camera.emit('showCase')

                this.$eventHub.$emit('showInfoPopUp', {
                    iconPath: "",
                    title: "Power2Go Lade-Reise-Etui",
                    text: "chnell & elegant aufladen – zuhause & unterwegs: Mit dem Premium Lade-Reise-Etui und der magnetischen 'Click'-Ladestation für eine schnelle Aufladung in nur 3 Stunden."
                });
            },
            onHide: function () {
                this.$eventHub.$emit('hideInfoPopUp');
            }
        }
    }
}

export {vrScenes}

