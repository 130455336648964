<template>
    <div id="vr-wrap">

        <div class="btn left-btn" @click="previousInsight">
            <svg height="300" width="300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path d="M22.9 1.86L21.49.45 5.94 16l15.55 15.55 1.41-1.41L8.77 16 22.9 1.86z" data-name="Layer 8"/>
            </svg>
        </div>
        <div class="btn right-btn" @click="nextInsight">
            <svg style="transform: rotate(180deg);" height="300" width="300"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path d="M22.9 1.86L21.49.45 5.94 16l15.55 15.55 1.41-1.41L8.77 16 22.9 1.86z" data-name="Layer 8"/>
            </svg>
        </div>

        <div class="color-switch">
            <span id="black" @click="changeColor('Oral_B9_Dunkel_BaseColor.png')"></span>
            <span id="rosa" @click="changeColor('Oral_B9_Pink_BaseColor.png')"></span>
            <span id="white" @click="changeColor('Oral_B9_Weiss_BaseColor.png')"></span>
        </div>

        <a-scene v-pre vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false"
                 renderer="antialias: true;
                   colorManagement: true;
                   sortObjects: true;
                   physicallyCorrectLights: true;
                   maxCanvasWidth: 1920;
                   maxCanvasHeight: 1920;"
        >
            <a-entity scale="1 1 1" id="bathroom"
                      obj-model="obj: url(./models/bathroom/Bathroom.obj);"
                      material="transparent: true; opacity: 1; color: #ffffff; metalness: 0.5; metalnessMap: ./models/bathroom/Bathroom_Marble_Metallic.png; normalMap: ./models/bathroom/Bathroom_Marble_Normal.png; normalScale:0.2; roughness: 0.5; roughnessMap: ./models/bathroom/Bathroom_Marble_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/bathroom/Bathroom_Marble_BaseColor.png"
            >
            </a-entity>
            <a-entity scale="1 1 1" id="Spiegel"
                      obj-model="obj: url(./models/bathroom/Spiegel.obj);"
                      material="color: #ffffff; metalness: 1; roughness: 0; sphericalEnvMap: ./images/sky5.jpg"
            >
            </a-entity>

            <a-entity id="brush"
                      animation__1="property: position; dur: 2000; easing: easeInOutQuad; loop: false; to: 0 7.5 0; startEvents: showCharger;"
                      animation__2="property: position; dur: 2000; easing: easeInOutQuad; loop: false; to: 0 0 0; startEvents: showChargerEnd;">
                <a-entity scale="1 1 1" id="Oral_B_Plastik"
                          obj-model="obj: url(./models/Oral_B/Oral_B_Plastik.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; metalness: 0.6; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0.4; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
                >
                </a-entity>
                <a-entity scale="1 1 1" id="Oral_B_Kopf"
                          animation-mixer
                          obj-model="obj: url(./models/Oral_B/Oral_B_Kopf.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; metalness: 0.6; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0.4; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
                >
                </a-entity>
                <a-entity scale="1 1 1" class="display-panel" id="Oral_B_Panel"
                          obj-model="obj: url(./models/Oral_B/Oral_B_Panel.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; metalness: 0.6; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0.4; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
                >
                </a-entity>
                <a-entity scale="1 1 1" class="display-panel" id="Oral_B_Panel1"
                          obj-model="obj: url(./models/Oral_B/Oral_B_Panel.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; metalness: 0.6; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0.4; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor1.png"
                >
                </a-entity>
                <a-entity scale="1 1 1" class="display-panel" id="Oral_B_Panel2"
                          obj-model="obj: url(./models/Oral_B/Oral_B_Panel.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; metalness: 0.6; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0.4; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor2.png"
                >
                </a-entity>
                <a-entity scale="1 1 1" id="Oral_B_Chrom"
                          obj-model="obj: url(./models/Oral_B/Oral_B_Chrom.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; metalness: 1; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
                >
                </a-entity>
                <!--
                animation__1="property: components.material.material.color.emissive; dur: 2000; easing: easeInOutQuad; loop: true; to: red; startEvents: changeColor;"
                -->
                <a-entity scale="1 1 1" id="Oral_B_Light"
                          obj-model="obj: url(./models/Oral_B/Oral_B_Light.obj);"
                          material="transparent: true; opacity: 1; color: #ffffff; emissive:  #4e9d43;  emissiveIntensity:  1;  normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
                >
                </a-entity>
            </a-entity>
            <a-entity scale="1 1 1" id="Oral_B_Chrom"
                      obj-model="obj: url(./models/Oral_B/Oral_B_Chrom.obj);"
                      material="transparent: true; opacity: 1; color: #ffffff; metalness: 1; metalnessMap: ./models/brush/Oral_B9_Dunkel_Metallic.png; normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; roughness: 0; roughnessMap: ./models/brush/Oral_B9_Dunkel_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
            >
            </a-entity>
            <a-entity scale="1 1 1" id="Oral_B_Light"
                      obj-model="obj: url(./models/Oral_B/Oral_B_Light.obj);"
                      material="transparent: true; opacity: 1; color: #ffffff; emissive:  #4e9d43;  emissiveIntensity:  1;  normalMap: ./models/brush/Oral_B9_Dunkel_Normal.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brush/Oral_B9_Dunkel_BaseColor.png"
            >
            </a-entity>

            <a-entity scale="1 1 1" id="Succulent"
                      obj-model="obj: url(./models/Succulent/Succulent.obj);"
                      material="transparent: true; opacity: 1; color: #ffffff; metalness: 1; metalnessMap: ./models/Succulent/Succulent_Metallic.png; normalMap: ./models/Succulent/Succulent_Normal.png; roughness: 0; roughnessMap: ./models/Succulent/Succulent_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/Succulent/Succulent_BaseColor.png">
            </a-entity>


            <a-entity scale="1 1 1" id="RefillCap"
                      obj-model="obj: url(./models/bathroom/Refill_cap.obj);"
                      material="transparent: true; opacity: 0.85; color: #ffffff; metalness: 1; metalnessMap: ./models/bathroom/Bathroom_Marble_Metallic.png; normalMap: ./models/bathroom/Bathroom_Marble_Normal.png; roughness: 0; roughnessMap: ./models/bathroom/Bathroom_Marble_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/bathroom/Bathroom_Marble_BaseColor.png"
            >

            </a-entity>

            <!--
                <a-entity light="type: ambient; intensity: 0.85;"></a-entity>
                <a-entity position="74.516 133.543 51.166" light="intensity:  0.1;  type:  spot;  castShadow:  true;  groundColor:  #ffffff;  decay:  1.56;  penumbra:  0.36" rotation="-37.937827446792326 50.661501203262524 -54.04023332114899"></a-entity>


            <a-entity light="color: #BBB; type: ambient" data-aframe-default-light="" ></a-entity> -->
            <a-entity position="40 63 60"
                      light="color: #ffffff; intensity: 0.2; castShadow: true; groundColor: #ffffff; decay: -2.47"
                      rotation="-37.937827446792326 50.661501203262524 -54.04023332114899"></a-entity>


            <a-entity scale="100 100 100" id="brush-head-animation" gltf-model="url(./models/brush/Brush_Anim1.glb)"
                      visible="false"
                      animation-mixer
            ></a-entity>


            <a-entity scale="1 1 1" id="Sphere"
                      obj-model="obj: url(./models/bathroom/Sphere.obj);"
                      material="transparent: true; opacity: 1; color: #080baf; metalness: 1;  roughness: 0.1; sphericalEnvMap: ./images/sky5.jpg"
            >
            </a-entity>

            <a-entity scale="1 1 1" id="brushCut"
                      obj-model="obj: url(./models/brushCut/Oral-B_iO_9_Schnitt.obj);" visible="false"
                      material="transparent: true; opacity: 1; color: #ffffff; metalness: 1; metalnessMap: ./models/brushCut/Oral_B_io9_schnit_Metallic.png; normalMap: ./models/brushCut/Oral_B_io9_Schnit_Normal.png; roughness: 1; roughnessMap: ./models/brushCut/Oral_B_io9_schnit_Roughness.png; sphericalEnvMap: ./images/sky5.jpg; src: ./models/brushCut/Oral_B_io9_schnit_BaseColor.png"
            ></a-entity>

            <a-entity scale="0.03 0.03 0.03" position="60 68 14" id="phone" gltf-model="./models/phone/scene.gltf"
                      visible="false" rotation="-90 0 -25"></a-entity>
            <a-entity scale="0.1 0.1 0.1" id="brushCut_blitz" gltf-model="url(./models/brushCut/Untitled.glb)"
                      animation-mixer
            ></a-entity>

            <a-entity light="type: ambient; intensity: 0.85;"></a-entity>

            <a-entity id="camera" camera position="55 80 70" rotation="0 25 0"
                      animation__1="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 0 0 0; startEvents: BrushDetailDrive;"
                      animation__2="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 40 82 40; startEvents: BrushDetailDrive;"
                      animation__3="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 45 94.7022 11.96747; startEvents: microVibe;"
                      animation__4="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 0 35 0; startEvents: microVibe;"
                      animation__5="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 38.4 82.4 11.96747; startEvents: showOLED;"
                      animation__6="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 0 -15 0; startEvents: showOLED;"
                      animation__7="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 48.8 86 12.5; startEvents: showZahnfleisch;"
                      animation__8="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 0 45 0; startEvents: showZahnfleisch;"
                      animation__9="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 40 86 23; startEvents: showCharger;"
                      animation__10="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: -35 0 0; startEvents: showCharger;"
                      animation__11="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 25 96.2 1; startEvents: showCase;"
                      animation__12="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: -90 -90 0; startEvents: showCase;"
                      animation__13="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: -45 20 0; startEvents: showAI;"
                      animation__14="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 65.5 86.5 29.5; startEvents: showAI;"
                      animation__15="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: -25 45 0; startEvents: showRefillHead;"
                      animation__16="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 80 93 33; startEvents: showRefillHead;"
                      animation__17="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 40.33963 82.4 11.96747; startEvents: showSmartModes;"
                      animation__18="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 0 0 0; startEvents: showSmartModes;"
                      animation__19="property: position; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 40.33963 82.4 11.96747; startEvents: showMagneticDrive;"
                      animation__20="property: rotation; dur: 2000;
                           easing: easeInOutQuad; loop: false; to: 0 0 0; startEvents: showMagneticDrive;"
            >
            </a-entity>


        </a-scene>

    </div>
</template>

<script>
    import 'aframe'
    import 'aframe-extras'

    import {vrScenes} from '../assets/js/vrscenes.js';


    export default {
        name: 'VrView',
        props: {},
        created() {
            this.$eventHub.$on('nav-item-clicked', this.eventProcessor);
        },
        beforeDestroy() {
            this.$eventHub.$off('nav-item-clicked');
        },
        data() {
            return {
                vrScenes: vrScenes,
                currentScene: undefined,
                cameraEnabled: true,
                currentSceneName: undefined,
                switchVisible: false,
                DisplaySlideIndex: 0,
            }
        },
        methods: {
            eventProcessor(NavEntry) {
                if (NavEntry && NavEntry.VrView) {
                    if (NavEntry.VrView.setScene) {
                        this.setScene(NavEntry.VrView.setScene);
                    }
                }
            },
            setScene(sceneName) {
                console.log("Setting VR-Scene: " + sceneName);

                if (this.currentScene) {
                    this.processScene(this.currentScene, "onHide");
                }

                this.currentScene = this.vrScenes[sceneName];
                this.currentSceneName = sceneName;
                if (!this.currentScene) {
                    console.log("Error : " + sceneName + " not found!");
                    return
                }

                this.processScene(this.currentScene, "onShow");

            },
            setObjectState: function (sceneDef, context) {
                if (sceneDef.objectStates && sceneDef.objectStates[context]) {
                    Object.keys(sceneDef.objectStates[context]).forEach(objectId => {
                        const obj = document.getElementById(objectId);
                        if (!obj) {
                            console.log("Error object : " + objectId + " not found in VRScene!");
                            return
                        }

                        Object.keys(sceneDef.objectStates[context][objectId]).forEach(attributeName => {
                            console.log("Obj: #" + objectId + "[" + attributeName + "] = " + sceneDef.objectStates[context][objectId][attributeName])
                            obj.setAttribute(attributeName, sceneDef.objectStates[context][objectId][attributeName]);
                        });
                    });
                }
            },
            processScene(sceneDef, context) {
                this.setObjectState(sceneDef, context);

                if (sceneDef.callbacks && sceneDef.callbacks[context]) {
                    sceneDef.callbacks[context].call(this);
                }
            },
            nextInsight: function () {
                this.$eventHub.$emit('nav-next-item');
            },
            previousInsight: function () {
                this.$eventHub.$emit('nav-prev-item');
            },
            changeColor: function (color) {
                const brush = document.getElementById('Oral_B_Plastik').getObject3D('mesh')

                /*global THREE*/
                new THREE.TextureLoader().load("./models/brush/" + color,
                    texture => {
                        //Update Texture
                        brush.material.map = texture;
                        brush.material.needsUpdate = true;
                    },
                    xhr => {
                        //Download Progress
                        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
                    },
                    error => {
                        //Error CallBack
                        console.log("An error happened" + error);
                    }
                );
            }
        },
        mounted() {
            this.setScene("Highlights")
            var self = this
            document.getElementById('brush').addEventListener('model-loaded', function () {
                self.$eventHub.$emit('readyForAction');
            });

        }
    }
</script>

<style lang="scss" scoped>

    #vr-wrap {

        .btn {
            position: absolute;
            top: 50%;
            width: 11%;
            z-index: 2;
            display: flex;
            background: #fff;
            padding: 0.85rem;
            border-radius: 100%;
            align-items: center;

            svg {
                width: 100%;
                height: auto;
                fill: $ioBlue
            }
        }

        .left-btn {
            left: 2.5%;
        }

        .right-btn {
            right: 2.5%;
        }
    }

    .color-switch {
        position: absolute;
        right: 0.6rem;
        bottom: 7.9rem;
        width: 12.5%;
        display: flex;
        z-index: 1;
        align-items: center;
        flex-direction: column;


        span {
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 100%;
            padding: 0.8rem;
            margin-top: 1rem;
            box-shadow: 0 0 6px rgb(0 0 0 / 0.1);
            border: 2px solid #696969;
        }

        #black {
            background-color: #000;
        }

        #rosa {
            background-color: #ffc5ed;
        }

        #white {
            background-color: #fff;
        }
    }
</style>
