<template>
    <div class="white-overlay" :class="{'active' : visible}">
        <h2>More Infos</h2>
    </div>
</template>

<script>
    export default {
        name: 'BottomOverlay',
        data() {
            return {
                visible: false
            }
        },
        created() {
            this.$eventHub.$on('showBottomOverlay', this.showBottomOverlay);
            this.$eventHub.$on('hideBottomOverlay', this.hideBottomOverlay);
        },
        methods: {
            showBottomOverlay() {
                this.visible = true;
            },
            hideBottomOverlay() {
                this.visible = false;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .white-overlay {
        position: absolute;
        background: white;
        width: 100%;
        height: 50%;
        bottom: 0;
        padding: 1rem;
        z-index: 999;
        box-shadow: 0px -8px 16px rgb(0 0 0 / 0.25);
        transform: translateY(100%);
        transition: all .3s ease-in-out;

        h2 {
            margin-bottom: .5rem;
        }
    }

    .active {
        transform: translateY(0);
    }


</style>
