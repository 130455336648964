<template>
    <div class="side-wrap">
        <div id="mySidenav" class="sidenav">
            <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
            <h5>Select your Oral-B Device</h5>
            <a class="active" href="#">iO Series 9</a>
            <a href="#">iO Series 8</a>
            <a href="#">iO Series 7</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Sidebar',
        props: {},
        methods: {
            closeNav: function () {
                this.$emit('close')
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .sidenav {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 999;
        top: 0;
        left: 0;
        background: #fff;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 7.5rem;
        padding-left: 2rem;

        h5 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: $oralGray;
            max-width: 80%;
        }

        a {
            text-decoration: none;
            color: $oralGray;
            display: block;
            transition: 0.3s;
            font-size: 1rem;
            margin-bottom: .5rem;
            max-width: 80%;

            &.active {
                color: $ioBlue;
                font-weight: bold;
            }
        }
    }

    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 5%;
        font-size: 2rem;
        margin-left: 50px;
        color: #41aaaa;
    }


</style>
