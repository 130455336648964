<template>
    <div id="app">
        <Header/>
        <InfoPopUp/>
        <LoadingScreen v-if="DOMisLoading"/>
        <VrView/>
        <FooterNavigation/>
        <transition name="fade" mode="out-in">
            <OverlayIntro v-if="IntroVisible" v-on:closeIntro="closeIntro"/>
            <wizard v-if="WizardVisible" v-on:closeWizard="closeWizard"/>
        </transition>
        <BottomOverlay v-if="isVisible = false"/>
        <DesktopHint/>
    </div>
</template>

<script>
    import VrView from "./components/VrView";
    import FooterNavigation from "./components/FooterNavigation";
    import OverlayIntro from "./components/OverlayIntro";
    import InfoPopUp from "./components/InfoPopUp";
    import Header from "./components/Header";
    //import LearnMoreVideo from "./components/LearnMoreVideo";
    import BottomOverlay from "./components/BottomOverlay";
    import DesktopHint from "./components/DesktopHint";
    import Wizard from "./components/Wizard";
    import LoadingScreen from "./components/LoadingScreen";

    export default {
        name: 'App',
        components: {
            LoadingScreen,
            Wizard,
            DesktopHint,
            BottomOverlay,
            Header,
            InfoPopUp,
            OverlayIntro,
            FooterNavigation,
            VrView,
        },
        data() {
            return {
                IntroVisible: true,
                WizardVisible: false,
                DOMisLoading: true,
            }
        },
        methods: {
            closeIntro: function () {
                this.IntroVisible = false
                this.WizardVisible = true
            },
            closeWizard: function () {
                this.IntroVisible = false
                this.WizardVisible = false

                var camera = document.getElementById('camera')
                camera.emit('BrushDetailDrive')
            },
            closeLoadingScreen: function () {
                this.DOMisLoading = false
            }
        },
        mounted() {
            var timeSTamp = document.documentElement.dataset.buildTimestampUtc;
            console.log('%c' + timeSTamp, 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');

            this.$eventHub.$on('readyForAction', this.closeLoadingScreen)
        }
    }
</script>

<style lang="scss">

    @font-face {
        font-family: 'Neutraface 2 Text';
        src: url('./assets/fonts/Neutraface2Text-Book.woff2') format('woff2'),
        url('./assets/fonts/Neutraface2Text-Book.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Neutraface 2 Text';
        src: url('./assets/fonts/Neutraface2Text-Bold.woff2') format('woff2'),
        url('./assets/fonts/Neutraface2Text-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
    }


    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: "Neutraface 2 Text", sans-serif;
        font-weight: normal;
        background: #fff;
        line-height: 1.25;
        color: $oralBlue;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    body.aframe-inspector-opened {
        .header, .footer, .overlay, .learn-more, .info-popup, .btn, .white-overlay, .qr-code-hint, .wizard, .vr-switch, #webcam, .color-switch {
            display: none !important;
        }

        #vr-wrap {
            display: block !important;
            background: #2d373c;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    // HIGHLIGHT ELEMENT STYLES
    body.highlight-is-active {
        pointer-events: none;
    }

    .highlight {
        box-shadow: 0 0 0 99999px rgba(0, 0, 0, .8);
        position: relative;
        z-index: 9999;
        pointer-events: auto;
        transition: all 0.5s ease;
        transform: scale(1.4);
        border-radius: 25rem;
    }

    .a-canvas {
        //background: gray;
    }
</style>
