<template>
    <div class="footer">
        <FooterSubNavigation :nav-items="activeNav.subNav"/>
        <div class="main-nav">
            <a class="nav-element"
               v-bind:class="{ 'active': navEntry === activeNav, 'highlight': navEntry.title === activeHighlight }"
               @click="navItemClicked(navEntry,index)" v-for="(navEntry,index) in nav"
               :key="index">{{navEntry.title}}</a>
        </div>
    </div>
</template>

<script>

    import {nav} from '../assets/js/navigation.js';
    import FooterSubNavigation from "./FooterSubNavigation";

    export default {
        name: 'FooterNavigation',
        components: {FooterSubNavigation},
        props: {},
        data() {
            return {
                nav: nav,
                activeNav: undefined,
                activeNavIndex: undefined,
                activeHighlight: undefined,
            }
        },
        methods: {
            navItemClicked: function (navEntry,index) {
                this.$eventHub.$emit('nav-item-clicked', navEntry.onClick);
                this.activeNav = navEntry;
                this.activeNavIndex = index
            }
        },
        beforeMount() {
            this.activeNav = this.nav[Object.keys(this.nav)[0]];
        },
        mounted() {
            this.$eventHub.$on('Highlight', e => {
                this.activeHighlight = e.name
            });
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .footer {
        position: fixed;
        z-index: 998;
        left: 0;
        bottom: 0;
        width: 100%;
        animation: footer-highlight 4s ease-out 3s 1 normal;
        -webkit-animation: footer-highlight 4s ease-out 3s 1 normal;

        .main-nav {
            background: #fff;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .nav-element {
                padding: .4rem 0.85rem;
                margin: .75rem .25rem;
                letter-spacing: 0.01rem;
                color: $oralGray;
                font-size: 0.75rem;
                position: relative;
                transition: all .25s ease-in-out;


                &.active {
                    color: $ioBlue;
                    //font-weight: bold;
                    background: transparent;
                    border: 1px solid $ioBlue;
                    display: block;
                    //opacity: .6;
                    border-radius: 2rem;
                }
            }
        }
    }

</style>
