<template>
    <div class="wizard">

        <div v-if="StepIndex === 0" id="first-hint" class="hints">
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M67.355 63.175c-12.17 0-32.854-4.607-42.416-33.617l1.899-.626c5.6 16.988 15.603 27.421 29.732 31.011 10.578 2.688 19.653.326 19.743.302l.519 1.932c-.118.032-3.906.998-9.477.998z"/>
                <path d="M69.158 66.958a.999.999 0 01-.843-.461c-.298-.465-.162-1.083.303-1.381l6.054-3.874-4.942-2.952c-.474-.283-.629-.897-.346-1.371a.998.998 0 011.371-.346l6.33 3.781c.297.178.482.497.487.843.005.347-.169.671-.461.858L69.696 66.8a.9951.9951 0 01-.538.158z"/>
            </svg>
        </div>

        <div v-if="StepIndex === 1" id="second-hint" class="hints">
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100">
                <path d="M79.47 28.11h-.69l-1.47.08c-.91.06-1.81.16-2.71.27l-1.84.23h-.14c-.87 0-1.73-.06-2.59-.06h-2.91c-.48 0-1 .05-1.42.1l-.66.07-.45.05a1 1 0 00-.45.13 1.73 1.73 0 00-.3.25.65.65 0 00.28 1.08l.45.12a2.53 2.53 0 00.48.08 6.69 6.69 0 001 0c.72 0 1.44 0 2.16-.09s1.16-.12 1.73-.18c1-.1 1.89-.22 2.83-.33h.43a21 21 0 012.1.15 12.29 12.29 0 011.21.23c-.69.28-1.37.56-2.06.86s-1.58.7-2.35 1.08c-.95.45-1.88.92-2.81 1.38-.73.38-1.46.76-2.19 1.12l-2.68 1.31-.87.44c-.14.07-.29.13-.42.21l-1.39.78c-.86.48-1.7 1-2.55 1.47s-1.64 1-2.45 1.51c-1.51.93-3 1.94-4.44 2.93-.74.5-1.5 1-2.23 1.5l-2.88 2c-.23.16-.44.34-.66.51L45 48.77l-1.84 1.47-2.49 2c-.32.26-.61.53-.92.79l-1.52 1.32c-.63.54-1.24 1.09-1.85 1.64l-1.88 1.7-2.5 2.39c-.73.67-1.43 1.35-2.14 2-1.31 1.24-2.6 2.42-3.86 3.62-.69.66-1.38 1.32-2.05 2s-1.36 1.38-2 2.08c-.42.43-.84.87-1.24 1.31-.61.68-1.22 1.36-1.82 2a.65.65 0 10.92.91c.42-.49.85-1 1.28-1.45.29-.32.57-.64.86-.95.63-.66 1.25-1.33 1.89-2s1.31-1.34 2-2l2-1.92c1.56-1.45 3.11-2.92 4.65-4.39.61-.58 1.24-1.14 1.86-1.71L36.13 58l2.54-2.27c.76-.68 1.53-1.33 2.3-2 .61-.53 1.23-1 1.85-1.55s1.35-1.11 2-1.65c1-.77 1.95-1.54 2.91-2.32.87-.6 1.73-1.21 2.59-1.82.67-.48 1.36-.94 2-1.4l2.16-1.45c.79-.52 1.57-1.07 2.37-1.57 1.63-1 3.26-2 4.94-3l1.68-.94c.3-.17.61-.32.92-.48l2.79-1.37c1.42-.7 2.82-1.43 4.23-2.14.76-.38 1.54-.74 2.31-1.09s1.39-.63 2.09-.93l-.15.06 1.34-.47c.26-.1.52-.2.76-.32a1.15 1.15 0 00.27-.14.84.84 0 00.28-.31 1 1 0 00.1-.41.9.9 0 00-.23-.56 1.42 1.42 0 00-.46-.38H79.37a6.82 6.82 0 01-.23.77c-.09.26-.19.51-.29.77l-.06.14v-.07c-.38.88-.79 1.75-1.2 2.62s-.81 1.82-1.2 2.73c-.32.75-.65 1.5-1 2.25s-.78 1.68-1.17 2.52a.72.72 0 00-.06.5.6.6 0 00.3.38.65.65 0 00.49.07.62.62 0 00.39-.3c.42-.92.86-1.82 1.27-2.75s.7-1.61 1-2.42.84-1.9 1.27-2.84l.58-1.26c.12-.26.24-.52.35-.78s.26-.6.37-.91c.06-.15.1-.3.15-.45s.13-.37.18-.56a1.46 1.46 0 00-.39-1.48 1 1 0 00-.7-.22m-.14 1.09v.07-.07M78.73 31.1v-.07.07"/>
            </svg>
        </div>

        <div v-if="StepIndex === 2" id="third-hint" class="hints">
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 100">
                <path d="M72.15 20.72l-11.64 4.54a7.85 7.85 0 00-1.11-.42 1.5 1.5 0 00-.8 2.89 5.62 5.62 0 01.68.26 1.7 1.7 0 00.61.41l.54.23a17.29 17.29 0 012.28 1.89 266.51 266.51 0 00-43.8 46c-1.16 1.55 1.45 3 2.59 1.51a263.16 263.16 0 0143.4-45.48l5.26 4.67a1.52 1.52 0 002.56-1.06l1.32-14a1.51 1.51 0 00-1.89-1.44zm-1.34 4v-.23h.07z"/>
            </svg>
        </div>

        <div class="modal-wrap">
            <div class="info-text">
                <h2>{{Steps[StepIndex].info}}</h2>
            </div>
            <a class="next-btn" @click="nextStep">Next</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Wizard',
        props: {},
        data() {
            return {
                StepIndex: 0,
                Steps: [
                    {
                        name: 'Highlights',
                        info: "Discover the brand new features of th Oral-B iO Series"
                    },
                    {
                        name: 'Extras',
                        info: "Get a overview of all Accessoires"
                    },
                    {
                        name: 'Shop',
                        info: "Shop your favorite product"
                    },
                ]
            }
        },
        mounted() {
            this.$eventHub.$emit('Highlight', this.Steps[this.StepIndex]);
        },
        methods: {
            nextStep() {
                this.StepIndex = (this.StepIndex + 1);


                if (this.StepIndex === this.Steps.length) {
                    this.$emit('closeWizard');
                    this.$eventHub.$emit('Highlight', {name: "stop"});
                } else {
                    this.$eventHub.$emit('Highlight', this.Steps[this.StepIndex]);
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .wizard {
        //background: rgb(45 55 60 / 0.75);
        z-index: 10001;
        position: absolute;
        top: 0;
        opacity: 1;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        flex-direction: column;
        //padding: 0 3%;
    }

    svg {
        width: 65%;
    }

    .modal-wrap {
        background: #fff;
        opacity: .9;
        padding: 17px 20px;
        width: 100%;
        margin: auto;
        min-height: 35%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding: 1.5rem;
        flex-direction: column;

        .info-text {
            max-width: 85%;

            h2 {
                color: $oralGray;
                font-size: 20px;
                line-height: 24px;
            }
        }

        .next-btn {
            color: $oralGray;
            padding: 10px 50px;
            text-transform: uppercase;
            align-self: center;
            min-width: 35%;
            text-align: center;
            letter-spacing: 0.1em;
            background: transparent;
            width: 65%;
            border: 1px solid $ioBlue;
            border-radius: 25rem;
        }
    }

    .hints {
        position: absolute;
        left: -7%;
        width: 60%;
        bottom: 5%;
        transform: rotate(107deg);

        svg {
            width: 100%;
        }
    }

    #second-hint {
        position: absolute;
        left: 1%;
        width: 60%;
        bottom: 5%;
        transform: rotate(87deg);
    }

    #third-hint {
        left: 26%;
        width: 60%;
        bottom: 5%;
        transform: rotate(188deg) scaleX(-1);
    }

</style>
