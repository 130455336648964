<template>
    <div class="overlay">
        <div class="info-text">
            <h2 class="heading">
                Welcome to the Oral-B VR Explorer
            </h2>
            <p>Discover all the brand new features iO Brushes</p>
        </div>

        <div class="logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.18 20.4">
                <g data-name="Ebene 2">
                    <g data-name="Ebene 3" fill="#fff">
                        <path d="M75.41 1.26a1.58 1.58 0 00-1.61 1.58 1.54 1.54 0 001.55 1.59A1.61 1.61 0 0077 2.85a1.54 1.54 0 00-1.59-1.59zM74.57 13.55c.15-.66 1.67-7.19 1.67-7.19a.63.63 0 00-.53-.77h-3.8a.2.2 0 00-.2.15L71.44 7a.07.07 0 00.06.09h1.21a.07.07 0 01.07.08c-.14.59-1 4.25-1.26 5.55-.48 2.1-.11 2.87.66 3.25a3 3 0 001.91.18 6 6 0 002.18-.94.13.13 0 000-.18 7.46 7.46 0 01-.35-.69.13.13 0 00-.18-.06c-.85.37-1.28-.28-1.17-.73zM86.84 1.26a8.88 8.88 0 108.88 8.88 8.87 8.87 0 00-8.88-8.88zm0 15a6.09 6.09 0 116.08-6.08 6.08 6.08 0 01-6.08 6.04zM29.92 12.25c-.57 2.41-2.85 2.36-2.85 1.3 0-.81 1.48-.89 2.85-1.3zM50.15 7h-2.31l-.39 1.8h2.37c1.5-.03 1.78-1.8.33-1.8zm-.56 4.17h-2.65l-.44 2h2.72c1.42-.02 1.56-2.03.37-2.03zm11.33-.94a9.9 9.9 0 01-9.85 10.2H9.84A9.9 9.9 0 010 10.2 9.9 9.9 0 019.85 0h41.22a9.9 9.9 0 019.85 10.2zM17.63 9.08c0-2.88-1.77-5.29-5.66-5.31-4.43 0-7.11 3.44-7.08 7.47 0 2.76 1.68 5.26 5.65 5.26 5.33.02 7.06-4.59 7.09-7.42zM33 16.24a1.59 1.59 0 01-.19-1.24l1-4.62c.47-2.2-1.88-3.14-4.27-3.14-1.86 0-3.49.33-4.59 1.88l.38-1.75a2.82 2.82 0 00-3.18 1v-.85h-3l-1.88 8.72h3.47l.81-3.8c.38-1.79 1.28-2.13 2-2.13h4.73c.17-1.23 2.43-1.15 2.24-.32-.16.66-1.79.62-4.18 1-.51.09-2.72.58-2.72 2.8 0 2.69 3.4 3.41 5.58 1.84v.56zm6.84-12.12H36.3l-2.62 12.12h3.54zm14.1 8.34A2.62 2.62 0 0052.63 10a3.25 3.25 0 001.95-3.1c0-3.07-3.54-2.8-5.08-2.8h-4.76l-1 4.64h-2.91l-.52 2.37h2.92l-1.1 5.1h7c3.97.03 4.8-2.7 4.81-3.75zM11.75 6.84c-1.61 0-3 1.64-3 4.6a1.83 1.83 0 001.94 2.07c2.32 0 3-3.27 3-4.62a1.89 1.89 0 00-1.94-2.05zM96.51 1.71h-.78v-.46h2.07v.46H97v2.4h-.51zM98.26 1.15l1.46 1.77 1.46-1.77v3h-.5V2.49l-1 1.16-1-1.16v1.62h-.51z"/>
                    </g>
                </g>
            </svg>
        </div>

        <a class="start-btn" @click="close">Start</a>
    </div>
</template>

<script>
    export default {
        name: 'OverlayIntro',
        props: {},
        methods: {
            close() {
                // destroy the vue listeners, etc
                this.$emit('closeIntro');
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .overlay {
        z-index: 999;
        background: url("../assets/images/Oral-B_SONOS_ImageLibrary_Image7_small.jpg") no-repeat;
        background-size: cover;
        height: 100%;
        background-position: 28% 100%;
        position: absolute;
        top: 0;
        opacity: 1;
        left: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        flex-direction: column;
        //padding-left: 1rem;
    }


    svg {
        width: 65%;
    }

    .info-text {
        margin: auto 0;
        padding: 1.25rem 0 1.25rem 1.4rem;
        transform: translateY(70%);
        max-width: 90%;
        display: none;

        h2 {
            max-width: 85%;
            font-size: 1.5rem;
            color: white;
        }

        p {
            max-width: 90%;
            font-size: .8rem;
            color: white;
            margin: .75rem 0 0 0;
        }
    }


    .logo {
        max-width: 10rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 1.3rem;
        right: 1.4rem;

        img {
            width: 100%;
        }

    }

    .start-btn {
        padding: 0.7rem 1rem;
        text-transform: uppercase;
        align-self: center;
        min-width: 35%;
        text-align: center;
        margin-top: auto;
        margin-bottom: 7rem;
        letter-spacing: 0.1em;
        background: #ffffff;
        border-radius: 25rem;
        color: $ioBlue;
    }
</style>
